import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { Form, Formik } from "formik";
//Import Icons
import FeatherIcon from "feather-icons-react";
import InputHandler from "../../SharedComponents/Input/input";
import { Alert, DatePicker, Radio } from "antd";
import { get } from "lodash";
import moment from "moment/moment";
import { useQuery } from "react-query";
import { Case, Switch } from "react-if";
import ErrorPage from "../../SharedComponents/NotfoundPage";
import { useSelector } from "react-redux";
import { BASE_API, CRUD_API } from "../../../Api";
import LoadingPage from "../../SharedComponents/LoadingPage";

const PageSignupThree = () => {
  const navigate = useNavigate()

  const { isLogin } = useSelector(state => state?.user)

  useEffect(() => {
    if(isLogin){
      navigate('/account')
    }
  }, [isLogin]);

  const [notification, setNotification] = useState({
    message: undefined ,
    type: 'error',
    show : false
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().min(8,'Must be valid number').required("Phone is required"),
    date_of_birth: Yup.string().required("Date of birth is required"),
    gender: Yup.string().oneOf(['1','0']).required("Gender is required"),
  })
  const { link , tele_id} = useParams()

  const { data , isError , isLoading , isSuccess  } = useQuery('check_link', () => BASE_API.get(`/user/check-link/${link}`))
  const { status } = data?.data || 500
  const handleSubmit = (values) =>{
    CRUD_API.post('user/register',{...values , tele_id:tele_id})
    .then(()=> {
      setNotification({message:'Registered Successfully',type:'success' , show: true})
      setTimeout(() => {
        navigate('/')
      }, 3500);
    })
    .catch((err)=> setNotification({message:err?.response?.data?.message || "Account Not Registered",type:'error' , show: true}))
    .finally(()=>setTimeout(() => {
      setNotification({})
    }, 5000))
  }

  return (
    <Fragment>
      {notification?.show ? <Alert style={{position:'absolute',zIndex:'3',width:'100%'}} message={notification?.message} type={notification?.type} /> : <></> }
      <div className="back-to-home rounded d-none d-sm-block">
        <Link
          to="/"
          className="btn btn-icon btn-primary"
        >
          <i>
            <FeatherIcon icon="home" className="icons" />
          </i>
        </Link>
      </div>

    <Switch>
      <Case condition={isLoading}>
        <LoadingPage/>
      </Case>
      <Case condition={isError}>
        <ErrorPage/>
      </Case>
      <Case condition={isSuccess && status === 202}>
        <section className="bg-auth-home bg-circle-gradiant d-table w-100" style={{minHeight:'700px'}}>
          <div className="bg-overlay bg-overlay-white"></div>
          <Container>
              <Row className="justify-content-center">
                <Col lg={5} md={8}>
                  <Card className="shadow rounded border-0">
                    <CardBody>
                      <h4 className="card-title text-center">Signup</h4>
                      <Formik
                        validationSchema={validationSchema}
                        initialValues={{}}
                        validateOnChange
                        validateOnBlur
                        onSubmit={(values) => handleSubmit(values)}
                      >
                        {(formikProps) => (
                        <Form
                          className="login-form mt-4"
                        >
                          <Row>
                            <Col lg={12} className="mb-0">
                              <InputHandler
                                name={'name'}
                                label="Name"
                                placeholder="Name"
                              />
                            </Col>
                            <Col lg={12} className="mb-0">
                              <InputHandler
                                name={'phone'}
                                type="phone"
                                label="Phone"
                                placeholder="Phone"
                                maxLength={15}
                              />
                            </Col>
                            <Col lg={12} className="mb-4">
                              <DatePicker 
                                onChange={(e , date)=> formikProps.setFieldValue('date_of_birth' , date)} 
                                className="w-100" 
                                placeholder="Select Date of bitrh"
                                picker="year"
                                disabledDate={current =>current && current > moment('2014-12-31')}
                              />
                              {get(formikProps.errors,'date_of_birth') ? <small className="text-danger fw-bold" style={{fontSize:'0.8rem'}}>{get(formikProps.errors,'date_of_birth')}</small> : <></> }
                            </Col>
                            <Col lg={12} className="mb-4 text-center">
                              <Radio.Group
                                options={[
                                  { label:'Male' , value:'1'},
                                  { label:'Female' , value:'0'},
                                ]}
                                onChange={(e)=>formikProps.setFieldValue('gender',e.target.value)}
                                value={get(formikProps.values , 'gender' , undefined)}
                                optionType="button"
                                buttonStyle="solid"
                              />
                              {get(formikProps.errors,'gender') ? <div className="text-danger fw-bold mt-1" style={{fontSize:'0.8rem'}}>{get(formikProps.errors,'gender')}</div> : <></> }
                            </Col>
                            <Col lg={12} className="mb-0">
                                <div className="d-grid">
                                  <Button color="primary" type="submit">
                                    Register
                                  </Button>
                                </div>
                              </Col>
                            <Col xs={12} className="text-center">
                              <p className="mb-0 mt-3">
                                <small className="text-dark me-2">
                                  have an account ?
                                </small>
                                <Link
                                  to="/login"
                                  className="text-dark fw-bold"
                                >
                                  Sign In
                                </Link>
                              </p>
                            </Col>
                          </Row>
                        </Form>
                        )}
                      </Formik>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          </Container>
        </section>
      </Case>
    </Switch>
  

    </Fragment>
  );
}

export default PageSignupThree;
