import React, { useState } from "react"
import "./index.css"
import { useFormikContext } from "formik"
import { IoMdEye, IoMdEyeOff } from "react-icons/io"
import { get } from "lodash"
import { roundToTwoDecimals } from "../../../helpers/actions"

const InputHandler = ({
name = undefined,
label = 'Name',
placeholder = 'input',
type = 'text',
maxValue = '100',
minValue = 0 ,
maxLength = 30,
key = 'key',
hasSeparator = false,
hasMaxValue = false ,
hasLabel = true,
decimals = 4
}) => {

    const { values, setFieldValue , errors } = useFormikContext()
    const [showPass, setShowPass] = useState(true)

    const handleChange = (e) => {
        if(type === "float"){
            const pattern = /^\d+(\.\d*)?$/
            const isValid = pattern.test(e?.value)
            if (!isValid) {
                e.value = e?.value
                    .replace(/[^\d.]/g, '')   
                    .replace(/(\..*)\./g, '$1') 
                    .replace(/^(\d*\.\d*).*$/, '$1')
            }
            setTimeout(() => {
                if(+e?.value < minValue){
                    e.value = roundToTwoDecimals(minValue , decimals)
                    setFieldValue(name , e?.value)
                }
                if(+e?.value > maxValue){
                    e.value = roundToTwoDecimals(maxValue , decimals)
                    setFieldValue(name , e?.value)
                }
            }, 1000);
        }
        else if (type === "phone") {
            const pattern = /^\d{14}$/
            const isValid = pattern.test(e?.value)
            if (!isValid) {
                e.value = e?.value.replace(/[^\d]/g, "").slice(0, 14)
            }
        } else if (type === 'amount') {
            const pattern = /^\d*$/ 
            const isValid = pattern.test(e?.value)
            if (!isValid) {
                e.value = e?.value.replace(/[^\d]/g, "")
            }
            if (+e.value?.replaceAll(',', '') >= +maxValue?.toString()?.replaceAll(',', '')) {
                e.value = maxValue
            }
            setTimeout(() => {
                if(+e?.value < minValue){
                    e.value = roundToTwoDecimals(minValue , decimals)
                    setFieldValue(name , e?.value)
                }
                if(+e?.value > maxValue){
                    e.value = roundToTwoDecimals(maxValue , decimals)
                    setFieldValue(name , e?.value)
                }
            }, 1000);
        } else if (type === 'price') {
            const pattern = /^\d*\.?\d{0,3}$/
            const isValid = pattern.test(e.value)
            if (!isValid) {
                e.value = e.value.replace(/[^\d.]/g, "").replace(/(\..{3}).*/g, "$1")
            }
            if (hasSeparator) {
                e.value = e.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if (+e.value?.replaceAll(',', '') >= +maxValue?.toString()?.replaceAll(',', '') && hasMaxValue) {
                e.value = maxValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            setTimeout(() => {
                if(+e?.value < minValue){
                    e.value = roundToTwoDecimals(minValue , decimals)
                    setFieldValue(name , e?.value)
                }
                if(+e?.value > maxValue){
                    e.value = roundToTwoDecimals(maxValue , decimals)
                    setFieldValue(name , e?.value)
                }
            }, 1000);
        }
            setFieldValue(name, e?.value?.length ? e?.value : undefined)
    }

    return (
        <div className="InputHandler">
            {hasLabel ? <h6 className="InputHandler_label">{label}</h6> : <></> }
            <input
                name={name}
                type={type === "password" ? (showPass ? "password" : "text") : type}
                key={key}
                value={values[name] || get(values, name) || undefined}
                placeholder={placeholder}
                maxLength={maxLength}
                onChange={(e) => handleChange(e?.target)}
            />
            {errors[name] ? <div className="InputHandler_required">{errors[name]}</div> : <></> }
            {
                type === "password" ? (
                    <span className="ShowPass" onClick={() => {
                        setShowPass(!showPass)
                    }}>{ !showPass ? <IoMdEye size={24}/> : <IoMdEyeOff size={24}/> }
                    </span>
                ) : (<></>)
            }
        </div>
    )
}

export default InputHandler
