import { useQuery } from 'react-query';
import { Badge, Card, Col, Row } from 'reactstrap';
import { BASE_API } from "../../Api";
import { Case, Switch } from 'react-if';
import ErrorPage from "../SharedComponents/ErrorPage";
import LoadingPage from "../SharedComponents/LoadingPage";
import { formatNumberWithCommas, getOrdinal, handleNumber, handleProfitColor, handleProfitOperator, roundToTwoDecimals } from '../../helpers/actions';

const Top = () => {

  const { data , isError , isLoading , isSuccess , error } = useQuery('top_movers', () => BASE_API.get('/user/top-movers'))

  return (
    <Switch>
      <Case condition={isError}>
        <ErrorPage customError={error?.response?.status !==500} queryString={'top_movers'}/>
      </Case>
      <Case condition={isLoading}><LoadingPage color='primary' hasOverlay={false}/></Case>
      <Case condition={isSuccess}>
        <Row>
          <Col xs={12}>
            <div className="section-title mb-4">
              <h4>Top Earners</h4>
            </div>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          { data?.data?.data?.length ?
            data?.data?.data?.map((user,index)=>{
              return(
                <Col xl={4} lg={6} md={6} key={'top_mover'+index} className='my-3'>
                  <Card className="border-0 px-3 py-4 rounded shadow-lg position-relative">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="user-nav ">
                        <span className="user-name fw-bold">{user?.name || "Unknown"}</span>
                      </div>
                      <span className="text-muted">{getOrdinal(index + 1 )}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap">
                      <small className={`${handleProfitColor(user?.gain)} fw-bold px-2`}>{handleProfitOperator(roundToTwoDecimals(user?.gain , 3))}  %</small>
                      <small className={`${handleProfitColor(user?.total_profit)} fw-bold px-2`}>{formatNumberWithCommas(handleProfitOperator(roundToTwoDecimals(user?.total_profit)))} EGP </small>
                      <small className={`fw-bold px-2 ${handleProfitColor(user?.total_wallet , user?.base_wallet)}`}>{formatNumberWithCommas(roundToTwoDecimals(user?.total_wallet , 2))} EGP</small>
                    </div>
                  </Card>
                </Col>
              )
            })
            :<div className='w-100 text-center'><Badge className='w-25 fs-3 py-3 custom_m17_badge'>No Data.</Badge></div>
          }
        </Row>
      </Case>
    </Switch>
  );
}

export default Top;
