import React, { Suspense } from "react";
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import svgMap from "../../assets/images/svg-map.svg";
import logo from "../../assets/Media/footerLogo.png";
import BackToTop from "../../components/Layout/backToTop";
import teleImg from '../../assets/Media/telegram.png'
import emailImg from '../../assets/Media/gmail.png'
import faceImg from '../../assets/Media/facebook.png'
import whatsImg from '../../assets/Media/whatsapp.png'
import youtubeImg from '../../assets/Media/youtube.png'

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};
const Footer = () => {
  return (
    <Suspense fallback={Loader()}>
      <footer className="footer" style={{backgroundImage: `url(${svgMap})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
        <Container>
          <Row className="justify-content-center">
            <Col className="col-12">
              <div className="footer-py-30 text-center">
                <Link to="/" className="logo-footer">
                  <img src={logo} height="95" width='180' alt="" />
                </Link>
                <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                  {/* <li className="list-inline-item mx-1">
                    <Link to="" className="rounded" target="blank">
                      <img alt="" src={faceImg} width={24}/>
                    </Link>
                  </li> */}
                  <li className="list-inline-item mx-1">
                    <a href= "mailto: software.auxilium@gmail.com" className="rounded" target="blank">
                      <img alt="" src={emailImg} width={24}/>
                    </a>
                  </li>
                  {/* <li className="list-inline-item mx-1">
                    <Link href= "mailto: name@email.com"className="rounded" target="blank">
                      <img alt="" src={youtubeImg} width={24}/>
                    </Link>
                  </li> */}
                  <li className="list-inline-item mx-1">
                    <Link to="https://t.me/MrFoxegystocks" className="rounded" target="blank">
                      <img alt="" src={teleImg} width={24}/>
                    </Link>
                  </li>
                  <li className="list-inline-item mx-1">
                    <Link to="https://wa.me/qr/2CXVLW5OPA5HM1" className="rounded" target="blank">
                      <img alt="" src={whatsImg} width={24}/>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="py-2 footer-bar bg-footer">
          <Container className="text-center">
            <Row className="justify-content-center">
              <Col className="col-12">
                <div className="text-center">
                  <p className="mb-0"><span className="text-info" style={{letterSpacing:'2px'}}>Auxilium Software</span>  &copy; {(new Date().getFullYear())}  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
      <BackToTop />
    </Suspense>
  );
}

export default Footer;
