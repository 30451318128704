import { useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { IoEye } from "react-icons/io5";

const ModalView = ({ 
  data, 
  title = undefined, 
  show = false, 
  size = 'md' , 
  noDataMessage = 'No Data.',
  className = ''
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    data && show ? <>
      <div className={`text-white fs-5 fw-bold cursor-pointer mx-auto ${className}`} onClick={() => setIsOpen(true)}>
        <IoEye size={22} /> View
      </div>
      <Modal size={size} isOpen={isOpen} className='modal-dialog-centered' toggle={() => setIsOpen(!isOpen)}>
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>{title}</ModalHeader>
        <ModalBody>
          {data}
        </ModalBody>
      </Modal>
    </> : <div className='mx-auto'>{noDataMessage}</div>
  )
}

export default ModalView