import { Image } from 'antd'
import { Fragment, useEffect, useState } from 'react'
import { IoEye } from "react-icons/io5";
import { formatNumberWithCommas, getOrdinal, getTotalProfit, handleProfitColor, handleProfitOperator, roundToTwoDecimals } from '../../helpers/actions';
import moment from 'moment/moment';
import axios from 'axios';
import ModalView from '../SharedComponents/ModalView';
import { Table } from 'reactstrap';
import TimeLine from '../SharedComponents/TimeLine';


export const columns = [
  {
    name: 'Rank',
    minWidth: '80px',
    cell: (row) => <h6 className='text-capitalize fw-bold mx-auto'>{getOrdinal(row?.rank)}</h6>
  },
  {
    name: 'User',
    minWidth: '320px',
    filter: 'name',
    cell: row => <h6 className='text-capitalize fw-bold mx-auto'>{row?.name}</h6>
  },
  {
    name: 'Gain / Loss',
    minWidth: '200px',
    cell: row => {
      const profit = row?.total_profit
      return (
        <h6 className={`${handleProfitColor(profit)} fw-bold`}> {formatNumberWithCommas(handleProfitOperator(roundToTwoDecimals(profit , 2)))} EGP</h6>
      )
    }
  },
  {
    name: 'Total Wallet',
    minWidth: '200px',
    cell: row => {
      const profit = row?.total_profit
      const holdings = profit + 500000
      return (
        <h6 className={`${handleProfitColor(holdings , 500000)} fw-bold`}>{formatNumberWithCommas(roundToTwoDecimals(holdings , 2))} EGP</h6>
      )
  }
  },
  {
    name: 'Current Stocks',
    minWidth: '160px',
    cell: row => 
    <ModalView
      show={row?.active_orders?.length}
      title={`Current Stocks ( ${row?.name} )`}
      noDataMessage={'No Stocks Now .'}
      size='xl'
      className='text-secondary'
      data={
        <Table responsive bordered striped hover>
          <thead>
            <tr className='text-center'>
              <th>
                Code
              </th>
              <th>
                Quantity
              </th>
              <th>
                Price
              </th>
              <th>
                Total
              </th>
              <th>
                Gain / Loss
              </th>
              <th>
                Opeaned At
              </th>
            </tr>
          </thead>
          <tbody>
            {
              row?.active_orders?.map((order , index)=>(
                <tr key={index} className='text-center fw-bold'>
                  <td>
                    {order?.code}
                  </td>
                  <td className='text-info'>
                    {order?.quantity}
                  </td>
                  <td className='text-warning'>
                    {order?.price}
                  </td>
                  <td className='text-info'>
                    {formatNumberWithCommas(roundToTwoDecimals(+order?.quantity * +order?.price))}
                  </td>
                  <td className={`${handleProfitColor(+order?.profit)} fw-bold`}>
                    {formatNumberWithCommas(handleProfitOperator(roundToTwoDecimals(+order?.profit)))}
                  </td>
                  <td className='text-success fs-6'>
                    {moment(order?.created_at || new Date()).format('LLLL')}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      }
    />
  },
  {
    name: 'Actions',
    minWidth: '200px',
    cell: row => {
      return (
        <ModalView
          show={row?.logs?.length}
          title={`Actions For ( ${row?.name} )`}
          noDataMessage={'No Actions Now .'}
          size='lg'
          className='text-secondary'
          data={
            <TimeLine
              title={'Transactions'}
              data={row?.logs}
            />
          }
        />
      );
    }
  }
]
