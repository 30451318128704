import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/Media/Logo.png';
import { LuPower, LuUser2 } from "react-icons/lu";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { getInitials } from '../../helpers/actions';
import { useDispatch } from 'react-redux';
import { handleLogout } from '../Account/store';

const NavBar = ({
  data = undefined ,
  isLogin = false
}) => {

  const activeElement = localStorage.getItem('activePage')
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById('topnav').classList.add('activeNavbarElement-dark')
    const elements = document.querySelectorAll('.navigation-menu .sub-menu-item')
    window.scrollTo(0, 0);
    document.body.classList = '';
    if(activeElement !== 'NaN'){
      elements[activeElement].classList.add('activeNavbarElement-dark')
      elements[activeElement].classList.remove('activeNavbarElement-light')
    }
    window.addEventListener('scroll', ()=>scrollNavigation(elements), true);
    return () => {
      window.removeEventListener('scroll', ()=>scrollNavigation(elements), true);
    };
  }, []);

  const scrollNavigation = (elements) => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      if(activeElement !== 'NaN'){
        elements[activeElement].classList.add('activeNavbarElement-dark')
        elements[activeElement].classList.remove('activeNavbarElement-light')
      }
      document.getElementById('topnav').classList.add('nav-sticky');
    } else {
      if(activeElement !== 'NaN'){
        elements[activeElement].classList.add('activeNavbarElement-dark')
        elements[activeElement].classList.remove('activeNavbarElement-light')
      }
      document.getElementById('topnav').classList.remove('nav-sticky');
    }
  };

  const isToogleMenu = () => {
    const isToggle = document.getElementById('isToggle');
    if (isToggle) {
      isToggle.classList.toggle('open');
      var isOpen = document.getElementById('navigation');
      if (isOpen.style.display === 'block') {
        isOpen.style.display = 'none';
      } else {
        isOpen.style.display = 'block';
      }
    }
  };

  return (
    <Fragment>
      <header id="topnav" className="defaultscroll sticky">
        <div className="mx-5 align-items=center">
          <div className='company_logo_m17'>
            <Link className="logo" to="/">
              <span className="logo-light-mode">
                <img src={logo} className="l-dark" height="40" alt="" />
                <img src={logo} className="l-light" height="40" alt="" />
              </span>
              <img
                src={logo}
                height="40"
                className="logo-dark-mode"
                alt=""
              />
            </Link>
          </div>
          <div className="menu-extras">
            <div className="menu-item">
              <Link
                to="#"
                className="navbar-toggle"
                id="isToggle"
                onClick={isToogleMenu}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>
          
          <ul className="buy-button list-inline mb-0">
            { data && isLogin ?
              <li className="list-inline-item ps-1 mt-3">
                <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                  <DropdownToggle
                    href="/"
                    tag="a"
                    className="nav-link dropdown-user-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <div className="user-nav d-sm-flex d-none">
                      <span className="user-name fw-bold">{data?.user?.name || "Unknown"}</span>
                    </div>
                    <span className='avatar-content'>
                      {getInitials(data?.user?.name)}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem tag={Link} to="#" onClick={() => dispatch(handleLogout())}>
                      <LuPower size={14} className="mx-1" />
                      <span className="align-middle">Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
              :<li className="list-inline-item ps-1 mb-0">
                <Link to="/account">
                <button className='custom_user_login'>
                  <LuUser2 size={22} className='mx-1 '/>
                  Account
                </button>
                </Link>
              </li>
            }
          </ul>

          <div id="navigation">
            <ul className="navigation-menu nav-light">
              <li><Link to="/" className="sub-menu-item px-0 fs-6 mx-3">Home</Link></li>
              <li><Link to="/ranking" className="sub-menu-item px-0 fs-6 mx-3">Ranking</Link></li>
              <li><Link to="/about" className="sub-menu-item px-0 fs-6 mx-3">About</Link></li>
              <li><Link to="/rate-us" className="sub-menu-item px-0 fs-6 mx-3">Rate Us</Link></li>
            </ul>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default NavBar;
