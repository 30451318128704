import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import InputHandler from "../../SharedComponents/Input/input";
import { Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../store";
import { ls } from "../../../helpers/actions";
import { BASE_API } from "../../../Api";

const Login = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isLogin } = useSelector(state => state?.user)

  useEffect(() => {
    if(isLogin){
      navigate('/account')
    }
  }, [isLogin]);

  const [notification, setNotification] = useState({
    message: undefined ,
    type: 'error',
    show : false
  });

  const validationSchema = Yup.object({
    user_name: Yup.string().required("User Name is required"),
    password: Yup.string().required("Password is required"),
  })

  const handleSubmit = (values) =>{
    BASE_API.post('user/login',values)
    .then((res)=> {
      const container = document.getElementById('root')
      container.style.display = "none"
      dispatch(updateProfile(res?.data))
      ls.setItem('token',res?.data?.access_token)
      window.location.pathname = '/account'
      setTimeout(() => {
          container.style.display = "block"
      }, 500)
    })
    .catch((err)=> setNotification({message:err?.response?.data?.error || "Login Error",type:'error' , show: true}))
    .finally(()=>setTimeout(() => {
      setNotification({})
    }, 5000))
  }

  return (
    <Fragment>
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      {notification?.show ? <Alert style={{position:'absolute',zIndex:'3',width:'100%'}} message={notification?.message} type={notification?.type} /> : <></> }

      <section className="bg-home bg-circle-gradiant d-flex align-items-center">
        <div className="bg-overlay bg-overlay-white"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={5} md={8}>
              <Card className="login-page shadow rounded border-0 py-3">
                <CardBody>
                  <h4 className="card-title text-center">Login</h4>
                  <Formik
                    validationSchema={validationSchema}
                    initialValues={{}}
                    validateOnChange
                    validateOnBlur
                    onSubmit={(values) => handleSubmit(values)}
                  >
                    {() => (
                    <Form
                      className="login-form mt-4"
                    >
                      <Row>
                        <Col lg={12} className="mb-0">
                          <InputHandler
                            name={'user_name'}
                            label="User Name"
                            placeholder="User Name"
                          />
                        </Col>
                        <Col lg={12} className="mb-0">
                          <InputHandler
                            name={'password'}
                            type="password"
                            label="Password"
                            placeholder="Password"
                            maxLength={16}
                          />
                        </Col>
                        <Col lg={12} className="mb-0">
                            <div className="d-grid">
                              <Button color="primary" type="submit">
                                Login
                              </Button>
                            </div>
                          </Col>
                        <Col xs={12} className="text-center">
                          <p className="mb-0 mt-3">
                            <small className="text-dark me-2">
                              don't have an account ?
                            </small>
                            <Link
                              to="https://t.me/Auxilium_Software_bot"
                              className="text-dark fw-bold"
                              target="_blank"
                            >
                              Register
                            </Link>
                          </p>
                        </Col>
                      </Row>
                    </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

    </Fragment>
  );
}

export default Login;
