import { Image } from 'antd'
import { Fragment, useEffect, useState } from 'react'
import { IoEye } from "react-icons/io5";
import { formatNumberWithCommas, handleProfitColor, roundToTwoDecimals } from '../../../helpers/actions';
import Close from './CloseOrder';
import moment from 'moment/moment';
import axios from 'axios';
import { Badge } from 'reactstrap';

const handleStatusColor = (status)=>{
  switch (status) {
    case 'Pending':
      return 'warning';
  
    case 'Stop Loss':
    case 'Closed':
      return 'danger';
  
    case 'Active':
      return 'success';
  
    default:
      break;
  }
}


export const columns = [
  {
    name: 'Code',
    minWidth: '100px',
    filter: 'code',
    cell: row => <h6 className='text-capitalize text-white fw-bold mx-auto'>{row?.code}</h6>
  },
  // {
  //   name: 'Order Type',
  //   minWidth: '160px',
  //   cell: row => <h5 className={`${row?.type === 'Market' ? 'text-warning' : 'text-info'} fw-bold`}>{row?.type}</h5>
  // },
  {
    name: 'Quantity',
    minWidth: '160px',
    cell: row => <h6 className='text-info fw-bold'>{formatNumberWithCommas(row?.quantity)}</h6>
  },
  {
    name: 'Price',
    minWidth: '120px',
    cell: row => <h6 className='text-warning'>{row?.price}</h6>
  },
  // {
  //   name: 'Stop Loss',
  //   minWidth: '145px',
  //   cell: row => <h6 className='text-danger fw-bold'>{row?.stop_loss}</h6>
  // },
  {
    name: 'Total',
    minWidth: '140px',
    cell: row => <h6 className='text-info fw-bold'>{formatNumberWithCommas(roundToTwoDecimals(+row?.quantity * +row?.price))}</h6>
  },
  // {
  //   name: 'Chart',
  //   minWidth: '135px',
  //   cell: row => {
  //     const [previewOpen, setPreviewOpen] = useState(false);
  //     const [previewImage, setPreviewImage] = useState(row?.picture);
  //     return (
  //       <Fragment>
  //         <div className='text-white fs-5 fw-bold cursor-pointer mx-auto' onClick={() => {
  //           setPreviewOpen(true)
  //           setPreviewImage(row?.picture)
  //         }}>
  //           <IoEye size={22} /> View
  //         </div>
  //         <Image
  //           wrapperStyle={{
  //             display: 'none',
  //           }}
  //           preview={{
  //             visible: previewOpen,
  //             onVisibleChange: (visible) => setPreviewOpen(visible),
  //             afterOpenChange: (visible) => !visible && setPreviewImage(''),
  //           }}
  //           src={previewImage}
  //         />
  //       </Fragment>
  //     )
  //   }
  // },
  {
    name: 'Status',
    minWidth: '138px',
    cell: row => <h6 className={`text-${handleStatusColor(row?.status)} fw-bold`}>{row?.status}</h6>
  },
  {
    name: 'Gain / Loss',
    minWidth: '200px',
    cell: row => <h6 className={`${handleProfitColor(+row?.profit)} fw-bold`}>{formatNumberWithCommas(row?.profit)}</h6>
  },
  {
    name: 'Opened At / Closed At',
    minWidth: '350px',
    cell: row => <div>
      <div className='text-capitalize w-100 text-center fw-bold fs-6 my-1 text-success'>{moment(row?.created_at || new Date()).format('LLLL')}</div>
      <div className='text-capitalize w-100 text-center fw-bold fs-6 text-danger'>{row?.status === 'Closed' ? moment(row?.updated_at || new Date()).format('LLLL') : 'Not Closed Yet!'}</div>
    </div>
  },
  {
    name: 'Actions',
    minWidth: '200px',
    cell: row => {

      const status = row?.status 
      const currentHour = moment().hour();
      const currentMinute = moment().minute();
      const currentDay = moment().day();
      const startHour = 10;
      const endHour = 14; 
      const endMinute = 15
      const isWeekday = currentDay === 5 || currentDay === 6;
      return (
        status === 'Active' && 
        (
          (currentHour > startHour || (currentHour === startHour && currentMinute >= 0)) &&
          (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute))
        ) && 
        !isWeekday
      ) ? (
        <div className='d-flex justify-content-center w-100'>
          <Close id={row?.id} stock={row}/>
        </div>
      ) : (
        <h5 className='text-white'>No Actions.</h5>
      );
    }
  }
]
