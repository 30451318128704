import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// import { Notification } from "@src/Helpers/actions"
import { ls } from '../../helpers/actions'
import { BASE_API } from "../../Api"

export const handleLogout = createAsyncThunk(
  "handleLogout",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    await BASE_API.get('/user/logout')
      return fulfillWithValue()
  }
)

const initialState = {
  data: {},
  isLogin: false
}

export const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
      updateProfile: (state, action) => {
        state.isLogin = true 
        state.data = action?.payload
      }
    },
    extraReducers: (builder) => {
      builder
      .addCase(handleLogout.fulfilled, (state, action) => {
        state.isLogin= false
          state.data = {}
          ls.removeItem('token')
      }) 
      .addCase(handleLogout.rejected, (state, action) => {
        state.isLogin= false
          state.data = {}
          ls.removeItem('token')
      }) 
    //   .addCase(authMe.rejected, (state) => {
    //     state.isLogin = false 
    //     state.data = {}
    //     window.location.pathname = '/login'
    //   }) 
    }
})

export const { updateProfile} = user.actions

export default user.reducer