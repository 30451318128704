
export const Stocks = [
  {
    label: 'البنك التجاري الدولي' ,
    value :'COMI'
  },
  {
    label: 'السويدي اليكريتك' ,
    value :'SWDY'
  },
  {
    label: 'طلعت مصطفى' ,
    value :'TMGH'
  },
  {
    label: 'موبكو' ,
    value :'MFPC'
  },
  {
    label: 'أبوقير للأسمدة' ,
    value :'ABUK'
  },
  {
    label: 'ايسترن كومباني' ,
    value :'EAST'
  },
  {
    label: 'الإسكندرية لتداول الحاويات' ,
    value :'ALCN'
  },
  {
    label: 'اوراسكوم كونستراكشون ليميتد' ,
    value :'ORAS'
  },
  {
    label: 'المصرية للإتصالات' ,
    value :'ETEL'
  },
  {
    label: 'حديد عز' ,
    value :'ESRS'
  },
  {
    label: 'إي فاينانس' ,
    value :'EFIH'
  },
  {
    label: 'هيرمس' ,
    value :'HRHO'
  },
  {
    label: 'إعمار مصر' ,
    value :'EMFD'
  },
  {
    label: 'القابضة المصرية الكويتية' ,
    value :'EKHOA'
  },
  {
    label: 'مصرف أبو ظبي الإسلامي' ,
    value :'ADIB'
  },
  {
    label: 'بنك فيصل الإسلامي المصري' ,
    value :'FAIT'
  },
  {
    label: 'فوري' ,
    value :'FWRY'
  },
  {
    label: 'كريدي أجريكول' ,
    value :'CIEB'
  },
  {
    label: 'جهينة' ,
    value :'JUFO'
  },
  {
    label: 'سيدي كرير للبتروكيماويات' ,
    value :'SKPC'
  },
  {
    label: 'ايديتا' ,
    value :'EFID'
  },
  {
    label: 'بلتون المالية' ,
    value :'BTFH'
  },
  {
    label: 'بالم هيلز' ,
    value :'PHDC'
  },
  {
    label: 'جي بي اوتو' ,
    value :'GBCO'
  },
  {
    label: 'النساجون الشرقيون' ,
    value :'ORWE'
  },
  {
    label: 'مصر الجديدة للاسكان' ,
    value :'HELI'
  },
  {
    label: 'أموك' ,
    value :'AMOC'
  },
  {
    label: 'مستشفى كليوباترا' ,
    value :'CLHO'
  },
  {
    label: 'مدينة نصر للاسكان' ,
    value :'MASR'
  },
  {
    label: 'بى انفست القابضة' ,
    value :'BINV'
  },
]