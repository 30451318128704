import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';

const Section = () => {

    return (
        <Fragment>
            <section className="bg-half-200 bg-custom-m17 bg-animation-left crypto-home d-table w-100">
                <Container className="position-relative" style={{ zIndex: "1" }}>
                    <Row className="mt-5 mt-sm-0 justify-content-center">
                    <Col lg="11">
                        <div className="title-heading text-center">
                        <h1 className="mover text-white rounded-3">EG Stocks Competition</h1>
                        <h1 className="heading text-primary text-shadow-title mt-4 mb-3">
                            Analyze - Compete - Win
                        </h1>
                        <p className="para-desc mx-auto text-muted">
                            Showcase your skills to achieve the best deal and be part of an exciting journey of excellence!
                        </p>
                        </div>
                    </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}

export default Section;