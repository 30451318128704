import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import EBRAHIM from "../../assets/Media/MOHAMEDEBRAHIM.jpg"
import auxilium from "../../assets/Media/logoNoWord.png"

const sponsers = [
    {
        logo: EBRAHIM,
        name: 'وجهه نظر' ,
        user_name : 'MOHAMED EBRAHIM'
    },
    {
        logo: auxilium,
        name: 'Auxilium Software' ,
        user_name : (
            <Fragment>
                <div>AHMED ASHRAF</div>
                <div>MOHAMED ATEF</div>
            </Fragment>
        )
    }
]
const Details = () => {
    return (
        <Fragment>
            <section className="section">
                <Container>
                    <Row className="justify-content-center" data-aos="fade-right" data-aos-duration='500' data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <Col lg={10} md={10} sm={10} xl={10} xs={10} className="mt-4 mt-sm-0 pt-2 pt-sm-0 custom_about_card">
                            <div className="section-title">
                                <h4 className="title mb-3 mt-2">Who Are We ?</h4>
                                <p className="text-muted">We are a dedicated team fostering a community of aspiring traders and shareholders in Egypt.</p>
                                <p className="text-muted mb-0">Our goal is to empower individuals with the knowledge and skills needed to excel in the stock market through educational competitions.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60">
                    <Row className="justify-content-center" data-aos="fade-left" data-aos-duration='500' data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <Col lg={10} md={10} sm={10} xl={10} xs={10} className="order-2 order-md-1 custom_about_card">
                            <div className="section-title">
                                <h4 className="title mb-3">Aim of the competition :-</h4>
                                <p className="text-muted">The aim of our competition is to educate participants about investing in Egyptian stocks, enhance their financial literacy, and provide a platform for them to apply their investment strategies in a simulated environment.</p>
                                <ul className="list-unstyled text-muted mb-0">
                                    <li className="mb-0 my-1 py-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>We aim to educate skilled traders who understand the Egyptian stock market.</li>
                                    <li className="mb-0 my-1 py-1  d-flex"><span className="text-primary h5 me-2" style={{marginTop:'-3px'}}><i className="uil uil-check-circle align-middle"></i></span><div>Our competition helps individuals improve their trading strategies and gain practical experience. </div></li>
                                    <li className="mb-0 my-1 py-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Participants strive to become successful investors.</li>
                                </ul>
                                <div className="mt-4">
                                    <Link to="/account" className="btn btn-soft-primary"><i className="uil uil-wallet"></i> Go to wallet</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60">
                    <Row className="justify-content-center" data-aos="fade-right" data-aos-duration='500' data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <Col lg={10} md={10} sm={10} xl={10} xs={10} className="order-1 order-md-2 custom_about_card">
                            <div className="section-title">
                                <h4 className="title mb-3">Why join us and the prize :-</h4>
                                <p className="text-muted mb-0">Join us to gain valuable experience in stock market trading.</p>
                                <p className="text-muted mb-0">compete with peers in a risk-free setting.</p> 
                                <p className="text-muted mb-0">win attractive prizes that reward both knowledge and performance in stock selection and portfolio management.</p>
                                <div className="mt-4">
                                    <Link to="/ranking" className="btn btn-soft-primary"><i className="uil uil-arrow-growth"></i> See Ranking</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className='custom_container_sponser_m17'>
                    <Row className="justify-content-center mt-5">
                        <Col xs={12} className='custom_container_col_sponser_m17 mt-5 d-flex justify-content-evenly flex-wrap w-100'>
                            <div className="section-title text-center mb-4 pb-2 mt-5 w-100" data-aos="zoom-in">
                                <h1 className="header mb-4 text-uppercase">Sponsers</h1>
                            </div>
                            {
                                sponsers?.map((sponser)=>(
                                    <Col xs={8} sm={5} md={5} lg={4} className='custom_container_col_sponser_m17_card'>
                                        <Card style={{height:'315px'}} className={"text-center rounded-3 border-0 shadow-lg my-3"} name="team" id="teambox">
                                            <CardBody className='px-1'>
                                                <div className="position-relative">
                                                    <img
                                                        src={sponser?.logo}
                                                        className="img-fluid avatar avatar-ex-large rounded-circle shadow"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className={"content pt-3"}>
                                                    <h5 className="mb-0">
                                                        <span className="name text-dark text-uppercase">
                                                            {sponser?.name}
                                                        </span>
                                                    </h5>
                                                    <small className="designation text-muted text-uppercase">
                                                        {sponser?.user_name}
                                                    </small>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}

export default Details;