import React from 'react';
import './style.css'
import moment from 'moment';
const TimeLine = ({
  title,
  colors = ['#AE1212' , '#0E8A03'],
  data = [],
}) => {

  const handleAction = (text)=>{
    if(text?.includes('Buy')){
      return 'Buy'
    }else if(text?.includes('Sell')){
      return "Sell"
    }
  }

  const handleColor=(action)=>{
    return handleAction(action) === 'Buy' ? colors[1] : colors[0]
  }

  const getCode=(text)=>{
    const regex = /\b(?:of)\s+(\S+)/;
    const match = text.match(regex);
    if (match) {
      const code = match[1];
      return code
  }
  }

  return (
    <div className='custom_time_line_m17'>
      <h1>{title}</h1>
      <ul>
        {
          data?.map((item)=>(
            <li style={{'--accent-color': handleColor(item?.action)}}>
              <div className="date" style={{fontSize:'14px'}}>{moment(item?.created_at || new Date()).format('LLLL')}</div>
              <div className="title fs-4 fw-bold" style={{color:handleColor(item?.action)}}>{handleAction(item?.action)} {` (${getCode(item?.action)})`}</div>
              <div className="descr">{item?.action}</div>
            </li>
          ))
        }
      </ul>
    </div>
  );
}

export default TimeLine;
