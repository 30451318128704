import React, { useState } from 'react';
import CustomTable from '../../SharedComponents/Table/CustomTable';
import AddOrder from './AddOrder';
import { columns } from './Columns';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Table = () => {

  const [isOpen, setIsOpen] = useState(false)
  const [maxOrders, setMaxOrders] = useState(false);

  const { isLogin } = useSelector(state => state?.user)

    const currentHour = moment().hour();
    const currentMinute = moment().minute();
    const currentDay = moment().day();
    const startHour = 10;
    const endHour = 14; 
    const endMinute = 15
    const isWeekday = currentDay === 5 || currentDay === 6;

    const canAdd = 
    (
      (currentHour > startHour || (currentHour === startHour && currentMinute >= 0)) &&
      (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute))
    ) && 
    !isWeekday
    && maxOrders

  return (
    <CustomTable
      api='/user/orders'
      queryKey='user_orders'
      columns={columns}
      AddTitle={'Add Order'}
      hasAdd={canAdd}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      AddComponent ={<AddOrder setIsOpen={setIsOpen}/>}
      setCustomData={setMaxOrders}
      enabled={isLogin}
    />
  );
}

export default Table;
