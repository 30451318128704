import { useQuery } from 'react-query';
import { Badge, Card, Col, Row } from 'reactstrap';
import { BASE_API } from "../../Api";
import { Case, Switch } from 'react-if';
import ErrorPage from "../SharedComponents/ErrorPage";
import LoadingPage from "../SharedComponents/LoadingPage";
import { getOrdinal, handleNumber, handleProfitColor, handleProfitOperator, roundToTwoDecimals } from '../../helpers/actions';

const Top = () => {

  const { data , isError , isLoading , isSuccess , error } = useQuery('top_orders', () => BASE_API.get('/user/top-orders'))

  return (
    <Switch>
      <Case condition={isError}>
        <ErrorPage customError={error?.response?.status !==500} queryString={'top_orders'}/>
      </Case>
      <Case condition={isLoading}><LoadingPage color='primary' hasOverlay={false}/></Case>
      <Case condition={isSuccess}>
        <Row>
          <Col xs={12}>
            <div className="section-title mb-4">
              <h4>Best Orders</h4>
            </div>
          </Col>
        </Row>
        <Row>
          {data?.data?.data?.length ?
            data?.data?.data?.map((order,index)=>{
              return(
                <Col lg={4} md={6} key={'top_mover'+index} className='my-3'>
                  <Card className="border-0 p-4 rounded shadow-lg position-relative">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="user-nav d-sm-flex ">
                        <span className="user-name fw-bold">{order?.code || "Unknown"}</span>
                      </div>
                      <span className="text-muted">{getOrdinal(index + 1 )}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <small className={`fw-bold`}>{order?.name}</small>
                      <small className={`${handleProfitColor(order?.gain)} fw-bold`}>{handleProfitOperator(roundToTwoDecimals(order?.gain , 3))} %</small>
                    </div>
                  </Card>
                </Col>
              )
            })
            : <div className='w-100 text-center'><Badge className='w-25 fs-3 py-3 custom_m17_badge'>No Data.</Badge></div>
          }
        </Row>
      </Case>
    </Switch>
  );
}

export default Top;
