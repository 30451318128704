import React, { Fragment, useEffect } from 'react';
import NavBar from '../NavBar/NavBar';
import { setActiveNavbarElement } from '../../helpers/actions';
import Footer from '../Footer/Footer';
import Section from './Section';
import Details from "./Details"
import Aos from 'aos';
import 'aos/dist/aos.css';

const About = () => {

    setActiveNavbarElement(3)
    useEffect(() => {
        Aos.init();
    }, []);

    return (
        <Fragment>
            <NavBar />
            <Section/>
            <Details/>
            <Footer />
        </Fragment>
    );
}

export default About;
