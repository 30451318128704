import React from 'react';
import Chart from 'react-apexcharts';
import { formatNumberWithCommas, handleNumber, stringToNumber } from '../../helpers/actions';

const DashboardChart = ({
  series = [],
  total = '500,000',
  base = 500000
}) => {

  const options = {
    chart: {
      height: 350, // زيادة ارتفاع الرسم البياني
      type: "radialBar",
    },
    series,
    plotOptions: {
      radialBar: {
        track: {
          margin: 7, // زيادة المسافة بين الدوائر
        },
        dataLabels: {
          name: {
            fontSize: '20px', // تكبير حجم الخط لاسم السلسلة
            color: 'white',
          },
          value: {
            fontSize: '20px', // تكبير حجم الخط لقيمة السلسلة
            color: 'white',
            animate: {
              enabled: true,
              easing: 'linear',
              duration: 2500,
              from: 0,
              to: 100,
            },
            formatter: function(val) {
              return formatNumberWithCommas(handleNumber(stringToNumber(val) * base / 100))
            }
          },
          total: {
            show: true,
            label: 'Wallet',
            fontSize: '20px', // تكبير حجم الخط للنص الإجمالي
            color: 'white',
            formatter: function(val) {
              return formatNumberWithCommas(handleNumber(total)); // الرقم الذي ترغب في عرضه
            }
          }
        }
      }
    },
    labels: ['Original' ,'Gain','Cash', 'Loss'],
    colors: ['#1E90FF','#32CD32','#B8860B', '#e43f52'],
    responsive: [{
      breakpoint: 400,
      options: {
        plotOptions: {
          radialBar: {
            track: {
              margin: 5, // زيادة المسافة بين الدوائر
            },
            dataLabels: {
              name: {
                fontSize: '14px',
                offsetY: -5, // رفع النص لأعلى بمقدار أقل للشاشات الصغيرة
              },
              value: {
                fontSize: '14px',
                offsetY: -5, // رفع النص لأعلى بمقدار أقل للشاشات الصغيرة
              },
              total: {
                fontSize: '14px',
                offsetY: -5, // رفع النص لأعلى بمقدار أقل للشاشات الصغيرة
              }
            }
          }
        }
      }
    }]
  };

  return (
    <div>
      <Chart
        options={options}
        series={options.series}
        type={options.chart.type}
        height={options.chart.height}
      />
    </div>
  );
};

export default DashboardChart;