import { EncryptStorage } from 'encrypt-storage';
import toast from 'react-hot-toast'
import * as qs from "qs"

const LS_ENCRYPTION_KEY = 'auxilium_M17';
export const ls = new EncryptStorage(LS_ENCRYPTION_KEY, {});

export const setActiveNavbarElement=(number)=>{
    localStorage.setItem('activePage' , +number - 1)
}
export const setActiveAccountPage=(number)=>{
    localStorage.setItem('activeAccountPage' , +number - 1)
}


export const textTruncate=(text , maxLength = 10 , concatinationWord = '...')=>{
    return text.slice(0 , maxLength) + concatinationWord 
}

export const getInitials = (str) => {
    const results = [];
    const wordArray = str?.split(" ") || [];
    wordArray.forEach((e) => {
    results.push(e[0]);
    });
    return results.join("")?.slice(0, 2);
};

export const roundToTwoDecimals = (num, cells = 2) => {
    if( +num === 0){
        return 0
    }
    if (num) {
        return num.toFixed(cells);
    } else {
        return '0'.padEnd(cells + 2, '0'); // إرجاع '0.00' كقيمة افتراضية
    }
};

export const stringToNumber = (string = '' , separator = ',')=>{
    return +string.replaceAll(separator,'')
}

function appendFormData(formData, key, value) {
    if (value instanceof File) {
        formData.append(key, value)
    } else if (value instanceof Date) {
        formData.append(key, value.toISOString())
    } else if (typeof value === "boolean") {
        formData.append(key, value ? "true" : "false")
    } else {
        formData.append(key, value.toString())
    }
}

export function convertToFormData(obj, formData = new FormData(), parentKey = "") {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
        const value = obj[key]
        const propName = parentKey ? `${parentKey}[${key}]` : key
        if (value === null || value === undefined) {
            formData.append(propName, "")
        } else if (
            typeof value === "object" &&
            !(value instanceof File) &&
            !(value instanceof Date)
        ) {
            convertToFormData(value, formData, propName)
        } else if (Array.isArray(value)) {
            value.forEach((item, index) => {
            const arrayKey = `${propName}[${index}]`
            appendFormData(formData, arrayKey, item)
        })
        } else {
            appendFormData(formData, propName, value)
        }
    }
    }
    return formData
}

export const Notification = (text = "Please Fill All Fields", time = 2500, type = 'error', onClose, maxErrors = 2) => {
    const handleMessages = () => {
        if (typeof text === 'object') {
            
            return Object?.values(text)?.map((message, index) => {
                if (typeof message === 'string') {
                    if (index + 1 <= maxErrors) return <div style={{width:'100%'}} key={`message_${index}`}>{message}</div>
                } else return "Please Fill All Fields"
            })
        } else {
            return text
        }
    }

    toast.custom(<div>{handleMessages()}</div>, {duration: time, type, position:'top-center'})
    setTimeout(() => {
        if (onClose && typeof onClose === 'function') {
            onClose()
        }
    }, time + 100)
} 

export const getOrdinal = (index)=> {
    // تأكد من أن الفهرس هو عدد صحيح وغير سلبي
    if (isNaN(index) || index < 1 || !Number.isInteger(index)) {
        return 0
    }

    // تحديد المقطع (suffix) بناءً على قيمة الفهرس
    const lastDigit = index % 10;
    const lastTwoDigits = index % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return `${index}th`;
    }

    switch (lastDigit) {
        case 1:
            return `${index}st`;
        case 2:
            return `${index}nd`;
        case 3:
            return `${index}rd`;
        default:
            return `${index}th`;
    }
}

export const handleProfitColor = (profit , base = 0)=>{
    if(profit === base){
        return ''
    }else if(profit > base){
        return 'text-success';
    }else {
        return 'text-danger';
    }
}

export const handleProfitOperator = (profit, base = 0) => {
    if (+profit === base) {
        return ` ${  profit}`
    } else if (profit > base) {
        return `+ ${  profit}`
    } else {
        return `- ${profit * -1}`
    }
}

export const formatNumberWithCommas = (number)=> {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const handleNumber =(number)=>{
    if(number === 0){
        return roundToTwoDecimals(number ,3)
    }else if(number > 0) {
        return roundToTwoDecimals(number ,3)
    }else if(number < 0) {
        return roundToTwoDecimals(number*-1 ,3)
    }
}

export const BlockButton=(className = '.custom_button_action_m17')=>{
    let Button = document.querySelector(className)
    Button.disabled=true
    Button.style.cursor="not-allowed"
    Button.style.opacity="0.5"
}
export const OpenButton=(className = '.custom_button_action_m17')=>{
    let Button = document.querySelector(className)
    Button.disabled=false
    Button.style.cursor="pointer"
    Button.style.opacity="1"
}

export const drawCelebrate = () =>{
    let W = window.innerWidth;
    let H = window.innerHeight;
    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d");
    const maxConfettis = 150;
    const particles = [];
    
    const possibleColors = [
      "DodgerBlue",
      "OliveDrab",
      "Gold",
      "Pink",
      "SlateBlue",
      "LightBlue",
      "Gold",
      "Violet",
      "PaleGreen",
      "SteelBlue",
      "SandyBrown",
      "Chocolate",
      "Crimson"
    ];
    
    function randomFromTo(from, to) {
      return Math.floor(Math.random() * (to - from + 1) + from);
    }
    
    function confettiParticle() {
      this.x = Math.random() * W; // x
      this.y = Math.random() * H - H; // y
      this.r = randomFromTo(11, 33); // radius
      this.d = Math.random() * maxConfettis + 11;
      this.color =
        possibleColors[Math.floor(Math.random() * possibleColors.length)];
      this.tilt = Math.floor(Math.random() * 33) - 11;
      this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
      this.tiltAngle = 0;
    
      this.draw = function() {
        context.beginPath();
        context.lineWidth = this.r / 2;
        context.strokeStyle = this.color;
        context.moveTo(this.x + this.tilt + this.r / 3, this.y);
        context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
        return context.stroke();
      };
    }
    
    function Draw() {
      const results = [];
    
      // Magical recursive functional love
      requestAnimationFrame(Draw);
    
      context.clearRect(0, 0, W, window.innerHeight);
    
      for (var i = 0; i < maxConfettis; i++) {
        results.push(particles[i].draw());
      }
    
      let particle = {};
      let remainingFlakes = 0;
      for (var i = 0; i < maxConfettis; i++) {
        particle = particles[i];
    
        particle.tiltAngle += particle.tiltAngleIncremental;
        particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
        particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;
    
        if (particle.y <= H) remainingFlakes++;
    
        // If a confetti has fluttered out of view,
        // bring it back to above the viewport and let if re-fall.
        if (particle.x > W + 30 || particle.x < -30 || particle.y > H) {
          particle.x = Math.random() * W;
          particle.y = -30;
          particle.tilt = Math.floor(Math.random() * 10) - 20;
        }
      }
    
        return results;
    }
    
    window.addEventListener(
        "resize",
        function() {
        W = window.innerWidth;
        H = window.innerHeight;
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        },
        false
    );
    
    // Push new confetti objects to `particles[]`
    for (var i = 0; i < maxConfettis; i++) {
        particles.push(new confettiParticle());
    }
    
    // Initialize
    canvas.width = W;
    canvas.height = H;
    Draw();
}

export const Can = ({ action, resource, component, noActions = <>No Actions.</> }) => {
    const { permissions } = useSelector(state => state?.user)

    if (permissions?.includes(`${action}-${resource}`)) {
        return component
    } else {
        return noActions
    }

}

export const filterBuilder = (params, useQueryString = true, removeKeys = []) => {
    const result = {}
    try {
        const { filters, ...restParams } = params
        for (const key in filters) {
            if (!removeKeys?.includes(key)) {
            if (key && key.indexOf(";") !== -1) {
                key.split(";").map((_k) => {
                if (_k && _k.length) {
                    result[_k] = filters[key]
                }
                })
            } else {
            result[key] = filters[key]
            }
            }
        }
        if (useQueryString) {
            return qs.stringify({
            ...restParams,
            filter: result
            })
        }
        } catch (error) {}
        return result
    }

    export const getTotalProfit = (stocks = [])=>{
        let gain = 0
        stocks?.map((stock)=>{
            const profit = +stock?.profit 
            gain += profit
        })
        return gain 
    }