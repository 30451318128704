import React from 'react';
import "./index.css"
import { Link } from 'react-router-dom';
const ErrorPage = () => {
  return (
    <div className='error_page_main'>

      <div>
        <div className="starsec"></div>
        <div className="starthird"></div>
        <div className="starfourth"></div>
        <div className="starfifth"></div>
      </div>

      <div className="lamp__wrap">
        <div className="lamp">
          <div className="cable"></div>
          <div className="cover"></div>
          <div className="in-cover">
            <div className="bulb"></div>
          </div>
          <div className="light"></div>
        </div>
      </div>

      <section className="error_page_text">

        <div className="error__content">
          <div className="error__message message">
            <h1 className="message__title">Page Not Found</h1>
            <p className="message__text">"Unfortunately", the page you were trying to access is not available at the moment due to The competition is running.</p>
            <p className="message__text"> Please check back for the next round of the competition."</p>
          </div>
          <div className="error__nav e-nav">
            <Link className='e-nav__link' to={'/'}>Back To Home</Link>
          </div>
        </div>

      </section>

    </div>
  );
}

export default ErrorPage;
