import React, { Suspense, useEffect } from "react"
import Layout from "./components/Layout/index"
import {
  Route, Routes
} from "react-router-dom"

// Import Css
import "./assets/css/materialdesignicons.min.css"
import "./Apps.scss"

// import "./assets/css/colors/default.css";

// Include Routes
import routes from "./routes/allRoutes"
import withRouter from "./common/data/withRouter"
import { QueryClient, QueryClientProvider } from "react-query"
import { Toaster } from 'react-hot-toast'

const App = () => {
  const LoaderComponent = () => (
    <div id="">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  )

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: true,
        refetchOnReconnect: false,
        retry: false
      }
    }
  })

  useEffect(() => {
    // const ad = document.querySelector('.ad_m17')
    const loading = document.querySelector('.custom_loading_public_m17')
    // ad.style.display = 'block'
    loading.remove()
  }, []);

  return (
    <React.Fragment>
      <Suspense fallback={<LoaderComponent />}>
      <QueryClientProvider client={queryClient}>
        <Toaster/>
        <Routes>
          {routes.map((route, idx) => (route.isWithoutLayout ? (
              <Route
                path={route.path}
                element={route.component}
                key={idx} />
            ) : (
              <Route
                path={route.path}
                element={
                  <Layout hasDarkTopBar={route.isTopbarDark}>
                    {route.component}
                  </Layout>
                  // withLayout(route.component, route.isTopbarDark)
                }
                key={idx}
              />
            ))
          )}
        </Routes>
      </QueryClientProvider>
      </Suspense>
    </React.Fragment>
  )
}

export default withRouter(App)
