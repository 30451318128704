import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FcAutomatic , FcCandleSticks  , FcGraduationCap, FcSignature  } from "react-icons/fc";
import { FaMedal } from "react-icons/fa";

const data = [
  {
    id: 1,
    title: "Enter the Free Egyptian Stock Market Challenge",
    desc: "Sharpen your investment skills and compete for profits in Egypt's premier, cost-free stock market challenge",
    icon : <FcCandleSticks  size={60}/>,
    color: '#4caf50'
  },
  {
    id: 2,
    title: "Master the Egyptian Stock Exchange",
    desc: "Enhance your knowledge of financial markets and start your investment journey with expert guidance.",
    icon : <FcGraduationCap  size={60}/> ,
    color: '#78909c'
  },
  {
    id: 3,
    title: "Compete for Incredible Prizes in the Stock Market",
    desc: "Invest smartly in the Egyptian stock market and compete to win amazing financial rewards",
    icon : <FaMedal size={60} color='goldenrod'/>,
    color: 'goldenrod'
  },
]

const HomeSlider = () => {
  return (
    <Slider
      dots= {false}
      infinite= {true}
      autoplaySpeed= {7000}
      autoplay= {true}
      slidesToShow= {1}
      slidesToScroll= {1}
      className="owl-carousel owl-theme px-3"
    >
      {data.map((item, key) => (
        <div className="mt-100 mb-150 px-2" key={'slider'+key}>
          <div
            className="rounded shadow py-5"
          >
          <div className="commenter mt-4 text-center">
            <h1 className="mb-0" style={{color:item.color}}>
              {item.title}
            </h1>
            <h6 className='fw-bold' style={{color:'#001c28'}}>{item.desc}</h6>
            <div className='mt-3'>
              {item.icon}
            </div>
          </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}

export default HomeSlider;
