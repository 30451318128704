import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';

const Section = () => {

    return (
        <Fragment>
            <section className="bg-half-200 bg-custom-m17 bg-animation-left crypto-home d-table w-100">
                <Container className="position-relative" style={{ zIndex: "1" }}>
                    <Row className="justify-content-center mt-5">
                        <Col xs={12}>
                            <div className="title-heading text-center">
                                <h2 className="mb-4 text-white border-bottom border-2 mx-auto px-2" style={{width:'fit-content'}}>Leaderboard</h2>
                                <span className="text-white mx-auto mb-0">
                                    <div>Discover the top performers in our competition</div>
                                    Stay updated with the latest rankings and see who is leading the way in the Egyptian stock market challenge .
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
}

export default Section;