import React, { Fragment } from "react";
import NavBar from "../NavBar/NavBar";
import { setActiveNavbarElement } from "../../helpers/actions";
import Footer from "../Footer/Footer";
import Section from "./Section";
import TopMovers from "./Top";
import TopOrders from "./TopOrders";
import { Container } from "reactstrap";
import AdvancedTable from "../SharedComponents/AdvancedTable/AdvancedTable"
import { columns } from "./Columns";
import { useSelector } from "react-redux";

const Index = () => {

  setActiveNavbarElement(2)
  const { data , isLogin }  = useSelector(state => state?.user)

  return (
    <Fragment>
      <NavBar />
      <Section/>
      <section className="section">
        <Container style={{maxWidth:'90%'}}>
          <TopMovers/>
          {/* <div className="my-5"> */}
            <TopOrders/>
          {/* </div> */}
        </Container>
        {
          isLogin ? 
          <Container style={{maxWidth:'98%' ,marginTop:'50px'}}>
            <AdvancedTable
              queryKey={'rankin_orders_list'}
              api={'/user/ranking-list'}
              columns={columns}
              hasHeader={false}
            />
          </Container>
          :<></>
        }
      </section>
      <Footer />
    </Fragment>
  );
}

export default Index;
