import { Fragment } from "react"
import { TbReload } from "react-icons/tb"
import { useQueryClient } from "react-query"
import { Badge } from "reactstrap"

const ErrorPage = ({ queryString = undefined, className = 'mt-4' , customError = false}) => {
  const query = useQueryClient()
  return (
    <div className={`page_action_error fs-2 fw-bold text-danger ${className}`}>
      {
        customError ? 
        <div className='w-100 text-center'><Badge className='w-25 fs-3 py-3 custom_m17_badge'>No Data.</Badge></div>
        :
        <Fragment>
          Loading Error
          <TbReload size={26} color="#d91111b2" className="mx-1" onClick={(e) => {
            e.target.classList.add('playAnimation')
            setTimeout(() => {
              e.target.classList.remove('playAnimation')
              query.invalidateQueries(queryString)
            }, 800)
          }}/>
        </Fragment>
      }
      
    </div>
  )
}

export default ErrorPage
