import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useQuery, useQueryClient } from 'react-query'
import { BlockButton, Notification, OpenButton } from '../../../helpers/actions'
import { BASE_API } from '../../../Api'
import { Fragment, useState } from 'react'
import * as Yup from "yup";
import { Form, Formik } from 'formik'
import InputHandler from '../../SharedComponents/Input/input'
import { get } from 'lodash'
import { Case, Switch } from 'react-if'
import LoadingPage from '../../SharedComponents/LoadingPage';
import ErrorPage from '../../SharedComponents/ErrorPage';

const Close = ({ id , stock }) => {
  const query = useQueryClient()
  const [isOpen, setIsOpen] = useState(false)
  const [enabled, setEnabled] = useState(false);

  const { data , isLoading , isSuccess , isError } = useQuery('profile', () => BASE_API.get(`/user/stock-price/${stock?.code}`),{
    enabled : enabled
  })

  const validationSchema = Yup.object({
    quantity: Yup.string().required("Quantity is required"),
  })

  const close = (values) => {
    const quantity = get(values , 'quantity')
    if(quantity > 0)
    {
      BlockButton(`.custom_button_action_m17_${id}`);
      BASE_API.post(`user/close-order/${id}`,values)
      .then(() => {
        Notification('Sold Successfully', 2500, 'success')
        query.invalidateQueries('user_orders')
        query.invalidateQueries('user_statistics')
        setIsOpen(false)
      })
      .catch((err) => Notification(err?.response?.data?.message || 'Try again later'))
      .finally(()=> OpenButton(`.custom_button_action_m17_${id}`))
    }else {
      Notification("Please Enter Valid Data")
    }
  } 

  return (
    <Fragment>
      <Button color="danger" onClick={() => {
        setIsOpen(true)
        setEnabled(true)
      }}>Sell</Button>
      <Modal size={'md'} isOpen={isOpen} className='modal-dialog-centered custom_modal_m17'>
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>Sell {stock?.code}</ModalHeader>
        <ModalBody>
          <Switch>
            <Case condition={isError}>
              <ErrorPage queryString={'profile'}/>
            </Case>
            <Case condition={isLoading}>
              <LoadingPage hasOverlay={false} color='primary' spinnerClassName='mt-0 text-center'/>
            </Case>
            <Case condition={isSuccess}>
              <h4 className='fw-bold'>You Have : <span className='text-info'>{stock?.quantity}</span></h4>
              <h4 className='fw-bold'>Current Price : <span className='text-warning'>{data?.data?.price || 0}</span></h4>
              <Formik
                validationSchema={validationSchema}
                initialValues={{}}
                validateOnChange={true}
                onSubmit={(values) => close(values)}
              >
                {(formikProps) => (
                <Form
                  className="login-form text-center"
                >
                  <InputHandler
                    name={'quantity'}
                    type="amount"
                    placeholder="Quantity To Sell"
                    maxLength={9}
                    label='Quantity To Sell'
                    maxValue={stock?.quantity}
                    // minValue={1}
                    decimals={0}
                  />
                <Button color="danger" className={`custom_button_action_m17_${id}`} type='submit'>Sell {get(formikProps.values , 'quantity')}</Button>
                </Form>
                )}
              </Formik>
            </Case>
          </Switch>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default Close
