import React from 'react'
import Home from "../Views/Home/index";
import Ranking from "../Views/Ranking";
import About from '../Views/About';
import Account from '../Views/Account';
import Register from '../Views/Account/Auth/Signup';
import Login from '../Views/Account/Auth/Login';
import ErrorPage from '../Views/SharedComponents/ErrorPage';
import Rating from '../Views/Rating'

const routes = [
  { path: "/", component: <Home />, isWithoutLayout: true },
  { path: "/ranking", component: <Ranking />, isWithoutLayout: true },
  { path: "/about", component: <About />, isWithoutLayout: true },
  { path: "/account", component: <Account />, isWithoutLayout: true },
  { path: "/register/:link/:tele_id", component: <Register />, isWithoutLayout: true },
  { path: "/login", component: <Login />, isWithoutLayout: true },
  { path: "/rate-us", component: <Rating />, isWithoutLayout: true },
  { path: "*", component: <ErrorPage />, isWithoutLayout: true },
];

export default routes;
