import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Card, Col, Input } from 'reactstrap';
import { Rate } from 'antd';
import { CRUD_API } from '../../Api';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import InputHandler from "../SharedComponents/Input/input";
import FileUpload from '../SharedComponents/FileUplaod';
import { BlockButton, OpenButton } from '../../helpers/actions';

const Index = () => {

  const navigate = useNavigate()

  const [alert, setAlert] = useState({
    text: '' ,
    color: 'info',
    isOpen : false
});

  const validationSchema = Yup.object({
    logo: Yup.mixed().notRequired(),
    name: Yup.string().required("Name is required"),
    rate: Yup.string().required("Rate is required"),
    desc: Yup.string().required("Details is required"),
  })

  const handleSubmit = (values)=>{
    BlockButton()
    CRUD_API.post(`user/rate`,values)
    .then((res)=>{
      setAlert({
        text: 'Thank You !',
        color: 'success',
        isOpen: true
    })
    setTimeout(() => {
      navigate('/')
    }, 3000);
    })
    .catch((err)=>{
      OpenButton()
      setAlert({
        text: err?.response?.data?.message || 'Failed to send Your Request details',
        color: 'danger',
        isOpen: true
      })
    })
  }

  return (
    <Col lg='8' md='8' sm='10' xs='10' className='mx-auto mt-150'>
      <Card className='p-4 fs-4 rounded shadow-lg'>
        <Alert color={alert.color} isOpen={alert.isOpen} > {alert.text} </Alert>
        <h4 className="mb-5 mx-auto fw-bold text-info" >How satisfied are you with our services ?</h4>
        <Formik
          validationSchema={validationSchema}
          initialValues={{}}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => handleSubmit(values)}
        >
          {(formikProps) => (
          <Form
            className="login-form mt-4"
          >
            <Col lg={4} className="mb-0 d-flex flex-wrap justify-content-center mt-0 mx-auto">
              <FileUpload
                label='Upload Photo'
                labelClassList='text-center'
                name='logo'
              />
              {formikProps?.errors['logo'] ? <div className="InputHandler_required">{formikProps?.errors['logo']}</div> : <></> }
            </Col>
            <Col lg={7} className="mb-0 mx-auto">
              <InputHandler
                name={'name'}
                label="Name"
                placeholder="Enter Name"
                maxLength={40}
              />
            </Col>
            <Col lg={12} className="mt-4 justify-content-center d-flex flex-wrap">
              <h6 className="InputHandler_label mb-0 w-100 text-center">Rate Us</h6>
              <Rate allowHalf defaultValue={0} className='fs-1 mx-auto' onChange={(e)=>formikProps.setFieldValue('rate',e)}/>
              {formikProps?.errors['rate'] ? <div className="InputHandler_required w-100 text-center">{formikProps?.errors['rate']}</div> : <></> }
            </Col>
            <Col lg={10} className="mb-5 mx-auto">
              <h6 className="mb-0 mt-4" >More Details</h6>
              <Input
                type='textarea'
                name='details'
                id='details'
                maxLength={300}
                onChange={(e)=>{
                  formikProps.setFieldValue('desc', e?.target?.value)
                }}
                placeholder='Enter More Details (max 300 character) '
                style={{minHeight:'200px'}}
              />
              {formikProps?.errors['desc'] ? <div className="InputHandler_required">{formikProps?.errors['desc']}</div> : <></> }
            </Col>
            <Col lg={2} className="mb-0 mx-auto">
              <button type='submit' style={{backgroundColor:'rgb(8, 170, 8)'}} className="text-white fw-bold btn btn-block w-100 mx-auto custom_button_action_m17">Submit</button>
            </Col>
          </Form>
          )}
          </Formik>
      </Card>
    </Col>
  );
}

export default Index;
