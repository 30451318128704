import React, { Fragment } from 'react';
import { Container, Spinner } from 'reactstrap';

const LoadingPage = ({
  hasOverlay = true,
  color = 'white',
  spinnerClassName='mt-5'
}) => {
  return (
    <Fragment>
      {hasOverlay ? <div className="bg-overlay bg-overlay-white"></div> : <></> }
      <Container>
        <div className={`text-center ${spinnerClassName}`}>
          <Spinner
            color={color}
            style={{
              height: '6rem',
              width: '6rem',
              marginTop:'100px'
            }}
          >
            Loading...
          </Spinner>
        </div>
      </Container>
    </Fragment>
  );
}

export default LoadingPage;
