import { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { Case, Switch } from "react-if"
import { Button, Col, Row, Modal, ModalHeader, ModalBody, Badge } from "reactstrap"
import { useQuery, useQueryClient } from "react-query"
import { BASE_API } from "../../../Api"
import './react-dataTable-component.scss'
import ErrorPage from "../ErrorPage"


const CustomTable = ({
  hasRefresh = true,
  hasAdd = false,
  AddComponent = <></>,
  AddTitle = 'Add New',
  queryKey = undefined,
  api = undefined,
  columns = [],
  setIsOpen,
  isOpen,
  size = 'lg',
  buttonColor = 'success',
  setCustomData = undefined,
  enabled = true ,
}) => {

  const [tableData, setTableData] = useState([])
  const queryClient = useQueryClient()
  
    const query = useQuery(queryKey, () => BASE_API.get(api) ,{
      enabled: enabled
    })
    if(setCustomData){
      setCustomData(query?.data?.data?.custom_data)
    }

    useEffect(() => {
      if (query?.isSuccess) {
        setTableData(query?.data?.data?.data)
      }else {
        setTableData([])
      }
    }, [query?.data?.data?.data || query?.status])

  return (
    <div className='react-dataTable'>
      <DataTable
        persistTableHead={true}
        subHeader
        // sortServer
        // pagination
        responsive
        paginationServer
        columns={columns}
        className='react-dataTable bg-primary'
        data={tableData}
        subHeaderComponent={
          <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
            <Row className="justfy-content-between">
                <Col
                  xl='6' md="6" lg="6" sm="6" xs='6'
                  className='d-flex align-items-center justify-content-start flex-xl-nowrap flex-wrap flex-column p-0 custom_table_button_m17 '
                >
                {
                  hasRefresh ? 
                  <Button className="refresh_table" outline onClick={() => queryClient.invalidateQueries(queryKey)}>
                    Refresh Table
                  </Button>
                  : <></>
                }
                </Col> 
              <Col
                xl='6' md="6" lg="6" sm="6" xs='6'
                className='d-flex align-items-center justify-content-end flex-xl-nowrap flex-wrap flex-column p-0 custom_table_button_m17 '
              >
              {hasAdd ? 
                <Button className='add-new-user' color={buttonColor} onClick={() => setIsOpen(true)}>
                  {AddTitle}
                </Button> : <></>
              }
              </Col> 
              
            </Row>
          </div>
        }
        noDataComponent={
          <div className="bg-primary w-100 text-center" style={{border:'1px solid white' , borderTop:'0px'}}>
              <Switch>
                <Case condition={query?.isLoading}>
                  <div className="spinner-grow my-3" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </Case>
                <Case condition={query?.isSuccess}>
                  <h3 className='my-2 text-muted'>No Data.</h3>
                </Case>
                <Case condition={query?.isError}>
                  <h3 className='my-2 text-muted'><ErrorPage queryString={queryKey} className="mt-0"/></h3>
                </Case>
              </Switch>
          </div>
        }
      />
      <Modal isOpen={isOpen} className='modal-dialog-centered' size={size}>
        <ModalHeader className="custom_modal_m17" toggle={() => setIsOpen(!isOpen)}>{AddTitle}</ModalHeader>
        <ModalBody>
          {AddComponent}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default CustomTable
