import React, { Fragment, useEffect } from "react";
import { drawCelebrate, formatNumberWithCommas, getOrdinal, handleNumber, handleProfitColor, handleProfitOperator, roundToTwoDecimals, setActiveNavbarElement } from "../../helpers/actions";
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import Table from "./Orders/Table";
import DashboardChart from "./DashboardChart";
import { Badge, Card, Col, Row } from "reactstrap";
import { useQuery } from "react-query";
import { BASE_API } from "../../Api";
import { Case, Switch } from "react-if";
import ErrorPage from "../SharedComponents/ErrorPage";
import LoadingPage from "../SharedComponents/LoadingPage";
import ModalView from "../SharedComponents/ModalView";
import TimeLine from "../SharedComponents/TimeLine";

const Index = () => {

  setActiveNavbarElement('false')
  const navigate = useNavigate()

  const { isLogin , data } = useSelector(state => state?.user)

  const query = useQuery('user_statistics', () => BASE_API.get('/user/statistics'),{
    enabled: isLogin
  })

  useEffect(() => {
    if(!isLogin){
      navigate('/login')
    }
    // else {
    //   if(data?.user?.id === 451 && data?.user?.name === "Ayah Solara"){
    //     drawCelebrate()
    //     const canvas = document.querySelector('.celebration_m17')
    //     let opacity = 1;
    //     const interval = setInterval(() => {
    //       opacity -= 0.1;
    //       if (opacity <= 0) {
    //         clearInterval(interval); 
    //         canvas.style.display='none'; 
    //       } else {
    //         canvas.style.opacity = opacity; 
    //       }
    //     }, 1500);
    //   }
    // }
  }, [isLogin]);

  return (
    <Fragment>
      <NavBar data={data} isLogin={isLogin}/>
        <div style={{minHeight:'700px' , backgroundColor:'#001c28' , color:'white',padding:'100px 50px'}}>
          <Row className="mb-150 align-items-center justify-content-between position-relative">
            {/* {
              data?.user?.id === 451 && data?.user?.name === "Ayah Solara" ?
                <div className="celebration_m17">
                  <h1 className="celebration_m17_h1">Happy Birthday Ayah !</h1>
                  <canvas id="canvas" className="celebration_m17_canvas"></canvas>
                </div>
                :<></>
            } */}
            <Switch>
              <Case condition={query.isError}><ErrorPage queryString={'user_statistics'}/></Case>
              <Case condition={query.isLoading}><LoadingPage hasOverlay={false}/></Case>
              <Case condition={query.isSuccess}>
                {
                  query?.data?.data?.data ?
                  <div className='w-100 text-center mt-5'><Badge className='w-25 fs-3 py-3 custom_m17_badge'>No Data.</Badge></div>
                  :
                  <Fragment>
                    <Col md='5'>
                      <Card className="border-0 p-4 rounded shadow position-relative text-primary fw-bold custom_account_card">
                        <Row className="my-2">
                          <Col>My ranking :</Col>
                          <Col className="text-end">{getOrdinal(+query?.data?.data?.rank)}</Col>
                        </Row>
                        <Row className="my-2">
                          <Col>Total Gain / Loss</Col>
                          <Col className={`${handleProfitColor(query?.data?.data?.precentage)} text-end fw-bold`}>{roundToTwoDecimals(query?.data?.data?.precentage)} %</Col>
                        </Row>
                        <Row className="my-2">
                          <Col>To be first :</Col>
                          <Col className="text-end">{roundToTwoDecimals(query?.data?.data?.to_be_first)} %</Col>
                        </Row>
                        <Row className="my-2">
                          <Col>Best Stock :</Col>
                          <Col className="text-end d-flex align-items-center justify-content-end">
                            {query?.data?.data?.best_stock?.code} 
                            <small className={`${handleProfitColor(query?.data?.data?.best_stock?.profit)} m-0 mx-1 fw-bold`}>
                              {query?.data?.data?.best_stock?.profit ? handleProfitOperator(roundToTwoDecimals(query?.data?.data?.best_stock?.profit , 3)) : ''}
                              {query?.data?.data?.best_stock?.profit ? '%' : ''}
                              </small>
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col>TransActions</Col>
                          <Col className="text-end">
                            <ModalView
                              show={query?.data?.data?.logs?.length}
                              title={`Transactions`}
                              noDataMessage={'No Actions'}
                              size='lg'
                              className='text-secondary'
                              data={
                                <TimeLine
                                  title={'Transactions'}
                                  data={query?.data?.data?.logs}
                                />
                              }
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col md='6'>
                      <DashboardChart 
                        series={query?.data?.data?.series}
                        total={query?.data?.data?.total}
                        />
                    </Col>
                  </Fragment>
                }
              </Case>
            </Switch>
          </Row>
          <Table/>
        </div>
      <Footer />
    </Fragment>
  );
}

export default Index;
