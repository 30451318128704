import React, { Fragment } from 'react';
import { Container, Row, Col } from "reactstrap";

const items = [
    {
        img: require('../../assets/Media/createAccount.png'),
        title : 'Create Account',
        desc : 'Sign up and receive a virtual wallet instantly for free'
    },
    {
        img: require('../../assets/Media/analyize.png'),
        title : 'Analyze',
        desc : 'Strategize and analyze market trends and opportunities'
    },
    {
        img: require('../../assets/Media/openTrade.jpg'),
        title : 'Open Trades',
        desc : 'Use your virtual wallet to open trades'
    },
    {
        img: require('../../assets/Media/compete.jpg'),
        title : 'Win',
        desc : 'Compete and Win Real Cash Prizes'
    },
]

const About = () => {
    return (
        <Fragment>
            <section id='about' className="section bg-light" style={{paddingBottom:'200px'}}>
                <Container>
                    <Container className="mt-60">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                            <div className="section-title text-center mb-4 pb-2" data-aos="zoom-in">
                                <h2 className="header mb-4">How To Win ?</h2>
                            </div>
                            </Col>
                        </Row>
                        <Row className='flex-wrap justify-content-between'>
                        {
                            items.map((item , key)=>{
                                return(
                                    <Col 
                                        md="5" xs="12" lg={3}
                                        key={key} 
                                        className="mt-4 mx-0 py-2 howToWinHover" 
                                        data-aos="flip-left"
                                        data-aos-duration='1500' data-aos-delay={(key*500) + 500}
                                    >
                                        <div className="text-center">
                                        <div className="rounded p-4 shadow">
                                            <img
                                                src={item.img}
                                                height="100"
                                                className="mx-auto d-block"
                                                alt=""
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <h4 className="text-primary">
                                                {item.title}
                                            </h4>
                                            <p className="text-muted mb-0">{item.desc}</p>
                                        </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                        </Row>
                    </Container>
                </Container>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                    viewBox="0 0 2880 250"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M720 125L2160 0H2880V250H0V125H720Z"
                        fill="currentColor"
                    ></path>
                    </svg>
                </div>
            </div>
        </Fragment>
    );
}

export default About;