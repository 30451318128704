import axios from 'axios'
import { ls } from '../helpers/actions'
import { handleLogout } from '../Views/Account/store'
import { store } from '../redux/store'

// const API_URL = 'http://localhost:8000/api'
const API_URL = 'https://api.auxilium-software.com/api'

    const getToken = () => {
        const token = ls.getItem("token")
        return token
    }

    const SECRET_KEY = 'Mo7amed@tef17'

    const getCrudHeaders = () => {
    const token = getToken()
    if (token) {
        return {
        Authorization: `bearer ${token}`,
        'Content-Type': "multipart/form-data"
        }
    } else {
        return {
        'Content-Type': "multipart/form-data"
        }
    }
    }
    const getBaseHeaders = () => {
    const token = getToken()
    if (token) {
        return {
        Authorization: `bearer ${token}`,
        'Content-Type': "application/json"
        }
    } else {
        return {
        'Content-Type': "application/json"
        }
    }
    }

    export const CRUD_API = axios.create({
        baseURL: API_URL,
        headers: getCrudHeaders()
    })
    export const BASE_API = axios.create({
        baseURL: API_URL,
        headers: getBaseHeaders()
    })

    const handleErrors = (code) => {
        if (code === 401) {
            store.dispatch(handleLogout())
        }
    }

    CRUD_API.interceptors.response.use((res) => {
        return res
    }, (err) => {
        handleErrors(err?.response?.status)
        return Promise.reject(err)
    })
    BASE_API.interceptors.response.use((res) => {
        return res
    }, (err) => {
        handleErrors(err?.response?.status)
        return Promise.reject(err)
    })