import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Image, Upload } from 'antd';
import { useFormikContext } from 'formik';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const FileUpload = ({
  acceptedTypes = ['image/jpg', 'image/jpeg', 'image/png'] ,
  multiple = false ,
  maxLength = 1 ,
  name = 'picture',
  label = 'Upload' ,
  labelClassList = ''
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if(fileList?.length){
      if(maxLength === 1){
        setFieldValue(name , fileList[0]?.originFileObj)
      }else {
        
      }
    }else {
      setFieldValue(name , undefined)
    }
  }, [fileList]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  return (
    <>
    <h6 className={`text-dark mb-0 w-100 fw-bold ${labelClassList}`}>{label}</h6>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={()=>false}
        accept={acceptedTypes}
        multiple={multiple}
        style={{width:'100%'}}
      >
        {fileList?.length >= maxLength ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: 'none',
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};
export default FileUpload;