import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import InputHandler from '../../SharedComponents/Input/input';
import * as Yup from "yup";
import { Alert, Select } from 'antd';
import axios from 'axios';
import { get } from 'lodash';
import { BlockButton, convertToFormData, Notification, OpenButton, roundToTwoDecimals, stringToNumber } from '../../../helpers/actions';
import FileUpload from '../../SharedComponents/FileUplaod';
import TextArea from 'antd/es/input/TextArea';
import { useQuery, useQueryClient } from 'react-query';
import { BASE_API, CRUD_API } from '../../../Api';
import { Case, Switch } from 'react-if';
import LoadingPage from '../../SharedComponents/LoadingPage';
import ErrorPage from '../../SharedComponents/ErrorPage';
import { Stocks } from "./Constants"

const AddOrder = ({ setIsOpen }) => {

  // const [options, setOptions] = useState([]);
  const [codeData, setCodeData] = useState(undefined);
  const [stockPrice, setStockPrice] = useState(0);
  const [reseting, setReseting] = useState(false);

  const handleResetIndicator = () => {
  setReseting(true);
  setTimeout(() => {
    setReseting(false);
  }, 500);
  }

  const { data , isLoading , isSuccess , isError , isFetching } = useQuery('profile', () => BASE_API.get('/user/profile'))
  const query = useQueryClient();

  const handleCodePrice = (code)=>{
    BASE_API.get(`/user/stock-price/${code}`)
    .then((res)=>setStockPrice(res?.data?.price))
    .catch(()=>setStockPrice(0))
  }

  // const handleStopLoss = (price , quantity) =>{
  //   const totalWallet = data?.data?.data?.total_wallet
  //   const stopLoss = stringToNumber(data?.data?.data?.stop_loss) / 100
  //   const stopMoney = totalWallet * stopLoss
  //   const orderStop = price - stopMoney
  //   const stop = orderStop / quantity

  //   return stop
  // }

  const validationSchema = Yup.object({
    code: Yup.string().required("Code is required"),
    quantity: Yup.string().required("Quantity is required"),
    // type: Yup.string().oneOf(['Limit','Market']).required("Order Type is required"),
    // stop_loss: Yup.string().required("Stop Loss Point is required"),
    // reason: Yup.string().min(100 , 'Enter Valid Reason at least 100 char').required("Reason is required"),
    // price: Yup.string().when('type', {
    //   is: (field) => field === 'Limit',
    //   then: schemas => schemas.required("Buying Price is required"),
    //   otherwise: schemas => schemas.notRequired()
    // }),
    // picture: Yup.mixed().required('Picture is required')
  })
  
  // let typingTimer 
  // const handleInputKeyDown = () => {
  //   clearTimeout(typingTimer)
  // }
  // const handleInputChange = (value) => {
  //   clearTimeout(typingTimer)
  //   typingTimer = setTimeout(() => {
  //     const finalValue = value?.length ? value : undefined
  //     handleCodeFilter(finalValue)
  //   }, 500) 
  // }

  // const handleCodeFilter = (value)=>{
  //   const code = value?.toUpperCase()
  //   axios.get(`https://scanner.tradingview.com/symbol?symbol=EGX%3A${code}&fields=close,open,market,name,description`)
  //   .then((res)=>{
  //     setOptions([{label:res?.data?.description , value: res?.data?.name , data:res?.data}])
  //     setCodeData(res?.data)
  //   })
  //   .catch((err)=>{
  //     setOptions([])
  //   })
  // }

  const handleSubmit = (values) =>{
    const quantity = get(values,'quantity')
    const maxValue = handleMaxValue()
    if(quantity<= maxValue && stockPrice > 0 && quantity > 0){
      BlockButton()
      CRUD_API.post('/user/order',convertToFormData({...values ,order_value: handleOrderValue(quantity) , price : stockPrice}))
      .then(()=>{
        handleResetIndicator();
        Notification("Order Added Successfully",3500,'success')
        setIsOpen(false)
      })
      .catch((err)=>Notification(err?.response?.data?.message ||"Order not Added"))
      .finally(()=>{
        query.invalidateQueries('user_orders')
        query.invalidateQueries('user_statistics')
        OpenButton()
      })
    }else {
      Notification("Please Enter Valid Data")
    }
  }
  
  const handleOrderValue = (quantity)=>{
    const Price = stockPrice
    const Quantity = stringToNumber(quantity)
    return roundToTwoDecimals(Price * Quantity)
  }

  const handleMaxValue = () =>{
    const Price = stockPrice
    const Holdings = data?.data?.data?.holdings 
    return Math.floor(Holdings / Price)
  }

  return (
    <Card className="shadow-lg border" style={{borderRadius:'20px'}}>
      <CardBody className='mt-4'>
        <Switch>
        <Case condition={isError}>
          <ErrorPage queryString={'profile'}/>
        </Case>
        <Case condition={isLoading || reseting}>
          <LoadingPage hasOverlay={false} color='primary' spinnerClassName='mt-0 text-center'/>
        </Case>
        <Case condition={isSuccess}>
          <Formik
              validationSchema={validationSchema}
              initialValues={{}}
              validateOnChange={false}
              validateOnBlur={false}
              isInitialValid={false}
              onSubmit={(values , formikProps) => handleSubmit(values , formikProps)}
            >
              {(formikProps) => (
              <Form
                className="login-form"
              >
                <Row>
                  <Col lg={6} className="mb-3 p-1">
                    <Select
                      options={Stocks}
                      className='w-100 custom-select-m17 mb-1'
                      placeholder='Code'
                      // allowClear
                      showSearch
                      // onInputKeyDown={handleInputKeyDown}
                      // onSearch={(value)=>{
                      //   handleInputChange(value)
                      // }}
                      // onClear={()=>{
                      //   setOptions([])
                      //   setCodeData(undefined)
                      //   formikProps.setFieldValue('code',undefined)
                      // }}
                      onSelect={(opt)=>{
                        handleCodePrice(opt)
                        formikProps.setFieldValue('code',opt)
                        formikProps.setFieldValue('quantity' , '1')
                      }}
                    />
                {formikProps.errors['code'] ? <div className="InputHandler_required">{formikProps.errors['code']}</div> : <></> }
                  </Col>
                  <Col lg={5} className="mb-0 p-1 custom_order_title text-success fw-bold fs-5 text-center">Current Price : {stockPrice}</Col>
                  {/* <Col lg={6} className="mb-0 p-1">
                    <Select
                      options={[
                        {label:'Limit' , value:'Limit'},
                        {label:'Market' , value:'Market'}
                      ]}
                      className='w-100 custom-select-m17 mb-1'
                      placeholder='Order type'
                      onSelect={(opt)=>formikProps.setFieldValue('type',opt)}
                      onChange={()=>formikProps.setFieldValue('price',undefined)}
                    />
                {formikProps.errors['type'] ? <div className="InputHandler_required">{formikProps.errors['type']}</div> : <></> }
                  </Col> */}
                  <Col lg={6} className="mb-0 p-1">
                    <InputHandler
                      name={'quantity'}
                      type="amount"
                      placeholder="Quantity"
                      maxLength={9}
                      // hasLabel={false}
                      label='Quantity'
                      maxValue={handleMaxValue()}
                      // minValue={1}
                    />
                  </Col>
                  {/* { get(formikProps.values , 'type') === 'Limit' ?
                    <Col lg={6} className="mb-0 p-1">
                      <InputHandler
                        name={'price'}
                        type="price"
                        placeholder="Buying Price"
                        maxLength={6}
                        hasLabel={false}
                        minValue={0.001}
                      />
                    </Col>
                    :<></>
                  } */}
                    {/* <Col lg={6} className="mb-0 p-1">
                      <InputHandler
                        name={'stop_loss'}
                        type="float"
                        placeholder="Stop Loss Point"
                        maxLength={6}
                        hasLabel={false}
                        maxValue={stringToNumber(get(formikProps.values,'price')) || codeData?.close}
                        minValue={handleStopLoss(handleOrderValue(get(formikProps.values,'price') , get(formikProps.values,'quantity')),stringToNumber(get(formikProps.values,'quantity')))}
                      />
                    </Col> */}
                    <Row className='my-4 custom_orders_holdings'>
                      <Col lg={4} className="d-flex custom_order_title justify-content-between mb-3 px-3 py-2 rounded-3 shadow-lg mx-1 mb-0 p-1 text-success fw-bold fs-6"><span>Holdings</span>  <span>{data?.data?.data?.holdings || 0}</span></Col>
                      <Col lg={4} className="d-flex custom_order_title justify-content-between mb-3 px-3 py-2 rounded-3 shadow-lg mx-1 mb-0 p-1 text-danger fw-bold fs-6"><span>Order Value</span> <span>{handleOrderValue(get(formikProps.values,'quantity'))}</span></Col>
                      <Col lg={4} className="d-flex custom_order_title justify-content-between mb-3 px-3 py-2 rounded-3 shadow-lg mx-1 mb-0 p-1 text-primary fw-bold fs-6"><span>Buying Power</span> <span>{roundToTwoDecimals(data?.data?.data?.holdings - handleOrderValue(get(formikProps.values,'quantity')))}</span></Col>
                    </Row>
                  {/* <Col lg={9} className="mb-0 mx-auto mt-2 mb-4">
                    <TextArea
                      maxLength={1200}
                      minLength={100}
                      rows={5}
                      style={{resize:'none'}}
                      showCount
                      placeholder='Why to choose this chart ?'
                      onChange={(e)=>formikProps.setFieldValue('reason',e?.target?.value)}
                    />
                    {formikProps.errors['reason'] ? <div className="InputHandler_required">{formikProps.errors['reason']}</div> : <></> }
                  </Col> */}
                  {/* <Col lg={3} className="mb-0 d-flex flex-wrap justify-content-center mt-0 mb-4">
                    <FileUpload
                      label='Upload Chart'
                      labelClassList='text-center'
                    />
                    {formikProps.errors['picture'] ? <div className="InputHandler_required">{formikProps.errors['picture']}</div> : <></> }
                  </Col> */}
                  <Col lg={4} className="mb-0 mx-auto mt-2">
                      <div className="d-grid">
                        <Button color="success" type="submit" className={'custom_button_action_m17'}>
                          Buy
                        </Button>
                      </div>
                    </Col>
                </Row>
              </Form>
              )}
          </Formik>
        </Case>
        </Switch>
      </CardBody>
    </Card>
  );
}

export default AddOrder;