import { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { Case, Default, Switch } from "react-if"
import { Button, Col, Input, Row, Card, Modal, ModalHeader, ModalBody } from "reactstrap"
import { Can, filterBuilder } from "../../../helpers/actions"
import { useQuery, useQueryClient } from "react-query"
import { BASE_API } from "../../../Api"
// import '@styles/react/libs/react-select/_react-select.scss'
// import '@styles/react/libs/tables/react-dataTable-component.scss'
import ReactPaginate from 'react-paginate'
import ErrorPage from "../ErrorPage"
import { FaAngleDown } from "react-icons/fa";

const AvancedTable = ({
  hasRefresh = true,
  hasAdd = false,
  AddComponent = <></>,
  AddTitle = 'Add New',
  customFilters = {},
  queryKey = undefined,
  api = undefined,
  columns,
  setIsOpen,
  isOpen,
  size = 'md',
  buttonColor = 'success',
  action,
  resource,
  hasHeader= true
}) => {

  const [filters, setFilters] = useState(
    {
      rows : 10,
      page : 1,
      ...customFilters
    }
  )

  const [tableData, setTableData] = useState([])
  const queryClient = useQueryClient()
  
  const handleFilters = (filter ,search = false) => {
    if (search) {
      filter.page = 1
    }
    setFilters({...filters, ...filter })
  }

    // take value of input after user stop
    let typingTimer 
    const handleInputKeyDown = () => {
      clearTimeout(typingTimer)
    }
    const handleInputChange = (value, filter) => {
      clearTimeout(typingTimer)
      typingTimer = setTimeout(() => {
        const finalValue = value?.length ? value : undefined
        handleFilters({[filter]:finalValue} , true)
      }, 500) 
    }

    const queryString = filterBuilder({filters}, true, ['rows', 'page'])
    const query = useQuery([queryKey, filters], () => BASE_API.get(`${api}?${queryString}&rows=${filters?.rows}&page=${filters?.page}`))
  
    useEffect(() => {
      if (query?.isSuccess) {
        setTableData(query?.data?.data)
      }
    }, [query?.data?.data?.data])

  const customHeader = (col) => {
    const { type = 'text', filter } = col 

  return (
    <div>
      <div className='text-center'>{col?.name}</div>
      <Switch>
        <Case condition={type === 'text' && filter }>
          <Input
            placeholder= {col?.name}
            className={'customTableSearch'}
            onKeyDown={handleInputKeyDown}
            onChange={(e) => handleInputChange(e?.target?.value, filter)}
          />
        </Case>
        <Case condition={type === 'select' && filter }>
            <Input
              className='customTableSearch'
              type='select'
              id='rows-per-page'
              onChange={(e) => handleFilters({[filter]:e?.target?.value} , true) }
              style={{ width: '100%' }}
            >
              <>
              { !col?.defaultValue ? <option value=''>ALL</option> : <></> }
                {
                  col?.options?.map((option) => {
                    return (
                      <option value={option?.value}>{option?.label}</option>
                    )
                  })
                }
              </>
            </Input>
        </Case>
        <Default></Default>
      </Switch>
    </div>
  )
  }

  const CustomPagination = () => {
    const count = Number(Math.ceil(tableData?.total / filters?.rows))
    return (
      <Row>
        <Col xl='6' md='6' lg='6' sm='6' xs='12' className='d-flex px-2 align-items-center p-0'>
          <div className='d-flex align-items-center w-100'>
            <label htmlFor='rows-per-page' className="mb-0 mx-1">Show</label>
            <Input
              className='mx-50'
              type='select'
              id='rows-per-page'
              value={filters?.rows}
              onChange={(e) => handleFilters({rows: +e?.target?.value} , true)}
              style={{ width: '5rem' }}
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
              <option value='250'>250</option>
              <option value='500'>500</option>
            </Input>
            <label htmlFor='rows-per-page' className="mb-0 mx-1">Entries</label>
          </div>
        </Col>
        <Col xl='6' md='6' lg='6' sm='6' xs='12' className='d-flex justify-content-end align-items-center p-0'>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={count || 1}
            activeClassName='active'
            forcePage={filters?.page !== 0 ? filters?.page - 1 : 0}
            onPageChange={curr => handleFilters({page: curr?.selected + 1})}
            pageClassName={'page-item'}
            nextLinkClassName={'page-link'}
            nextClassName={'page-item next'}
            previousClassName={'page-item prev'}
            previousLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
          />
        </Col>
      </Row>
    )
  }

  return (
    <div className='react-dataTable'>
      <DataTable
        persistTableHead={true}
        subHeader ={hasHeader}
        sortServer
        pagination
        responsive
        paginationServer
        columns={columns?.map((col) => ({
          ...col,
          name: customHeader(col)
        }))}
        sortIcon={<FaAngleDown />}
        className='react-dataTable'
        paginationComponent={CustomPagination}
        data={tableData?.data?.data}
        subHeaderComponent={
          <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
            <Row>
              {hasRefresh ? <Col
                xl='6' md="6" lg="6" sm="6"
                className='d-flex align-items-center justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column p-0'
                >
                  <Button color="primary" outline onClick={() => queryClient.invalidateQueries(queryKey)}>
                    Refresh Table
                  </Button>
                </Col> : <></>
              }

              {hasAdd ? <Col
                  xl='6' md="6" lg="6" sm="6"
                  className='d-flex align-items-center justify-content-end flex-xl-nowrap flex-wrap flex-sm-row flex-column p-0'
                >
                  <Can
                    action ={action}
                    resource={resource}
                    component={
                      <Button className='add-new-user' color={buttonColor} onClick={() => setIsOpen(true)}>
                        {AddTitle}
                      </Button>
                    }
                    noActions={<></>}
                  />
                </Col> : <></>
              }
              
            </Row>
          </div>
        }
        noDataComponent={
          <div className="w-100 text-center table_status_m17" style={{border:'1px solid white', borderTop:'0px'}}>
            <Switch>
              <Case condition={query?.isLoading}>
                <div className="spinner-grow my-3" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </Case>
              <Case condition={query?.isSuccess}>
                <h3 className='my-2 text-muted'>No Data.</h3>
              </Case>
              <Case condition={query?.isError}>
                <h3 className='my-2 text-muted'><ErrorPage queryString={queryKey} className="mt-0"/></h3>
              </Case>
            </Switch>
        </div>
        }
      />
      <Modal isOpen={isOpen} className='modal-dialog-centered' size={size}>
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>{AddTitle}</ModalHeader>
        <ModalBody>
          {AddComponent}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AvancedTable
