import React, { Fragment, useState } from "react";
import { Container, Row, Col, Badge } from "reactstrap";
import CountUp from "react-countup";
import { useInView } from 'react-intersection-observer';
import { BASE_API } from "../../Api";
import { useQuery } from "react-query";

const Counter = () => {

  const { data } = useQuery('home_statistics', () => BASE_API.get('/user/home-statistics'))

  const [startCount, setStartCount] = useState(false);
  const { ref } = useInView({
    triggerOnce: true, 
    onChange: (inView) => {
      if (inView) {
        setStartCount(true);
      }
    },
  });

  return (
    <Fragment>
      <section className="section-two bg-primary bg-gradient" ref={ref}>
        <Row id="counter" className="justify-content-evenly px-5">
          { data?.data?.data?.length ?
            data?.data?.data?.map((item , key)=>(
              <Col
                md="4"
                lg={2}
                sm={4}
                xs={10}
                key={'item'+key}
                className="mt-4 pt-2"
              >
                <div className="counter-box text-center">
                  <h2 className={`mb-0 mt-3 title-dark display-4 ${item?.color}`}>
                    <span className="counter-value">
                      {startCount && (
                        <CountUp
                          start={0}
                          end={item?.value}
                          duration={10}
                        />
                      )}
                    </span>
                  </h2>
                  <h5 className={`counter-head title-dark ${item?.color}`}>
                    {item?.label}
                  </h5>
                </div>
              </Col>
            ))
            :<div className='w-100 text-center'><Badge className='w-25 fs-3 py-3 custom_m17_badge'>No Data.</Badge></div>
          }
        </Row>
      </section>
    </Fragment>
  );
}

export default Counter;
