import React, { Fragment, useEffect } from "react";
import Section from "./Section";
import HowToWin from "./HowToWin";
import Counter from "./Counter";
import NavBar from "../NavBar/NavBar";
import { setActiveNavbarElement } from "../../helpers/actions";
import AOS from "aos";
import HomeSlider from "./HomeSlider";
import Footer from "../Footer/Footer";
import axios from "axios";
import 'aos/dist/aos.css';

const Index = () => {

  setActiveNavbarElement(1)
  useEffect(() => {
    AOS.init();
  }, []);

  // const code = "OLFI"

  // axios.get(`https://scanner.tradingview.com/symbol?symbol=EGX:${code}&fields=close,open,high,low&no_404=true`)
  // .then((res) => console.log(res))
  

  return (
    <Fragment>
      <NavBar />
      <Section />
      <HowToWin />
      <Counter/>
      <HomeSlider/>
      <Footer />
    </Fragment>
  );
}

export default Index;
